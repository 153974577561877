import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import { constants } from 'helpers'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { WidthContainer } from 'components/layout'
import { Text } from 'components/dataDisplay'

import GetStartedButton from '../GetStartedButton/GetStartedButton'
import FeatureItemList from '../FeatureItemList/FeatureItemList'

import type { HomePageCustomBlockButton } from '../../View'

import messages from './messages'


type HowItWorksSectionWithAnimationsProps = {
  className?: string
  startButtonMessage?: string | Intl.Message
  showStartButton?: boolean
  customButton?: HomePageCustomBlockButton
}

const HowItWorksSectionWithAnimations: React.FunctionComponent<HowItWorksSectionWithAnimationsProps> = (props) => {
  const { className, startButtonMessage, showStartButton = true, customButton } = props

  const isAiSummaryEnabled = useFeatureIsOn(constants.features.aiSummary)
  const isAiSummaryLandingHowFeatureEnabled = useFeatureIsOn(constants.features.aiSummaryLandingHow)
  // do not delete this line, it's required for AA tests
  const isHowItWorksEnabled = useFeatureIsOn(constants.features.aaHowItWorks)

  const { isMobile } = useDevice()

  const isAiSummaryLandingHowEnabled = isAiSummaryEnabled && isAiSummaryLandingHowFeatureEnabled

  return (
    <WidthContainer className={cx(className, 'flex flex-col items-center')}>
      <Text
        className={cx('text-center', isMobile ? 'mb-56' : 'mb-64')}
        style={isMobile ? 'h5' : 'h3'}
        message={messages.title}
        html
      />
      <FeatureItemList
        className={cx('flex', isMobile ? 'flex-col gap-40' : 'items-baseline gap-16')}
        isAiSummaryLandingHowEnabled={isAiSummaryLandingHowEnabled}
      />
      {
        showStartButton && (
          <GetStartedButton
            className="mt-56"
            title={startButtonMessage}
            width={320}
            fullWidthOnMobile
            placement="How it works"
            {...customButton}
          />
        )
      }
    </WidthContainer>
  )
}

export default React.memo(HowItWorksSectionWithAnimations)
